import React from 'react'
import Modal from "react-modal";
import ModalVideo from 'react-modal-video'
import { Link } from 'gatsby'
import FeatureCard from '../edmsComponents/FeatureCard'


Modal.setAppElement("#___gatsby");
const ProductFeature = ( {featureHeading, featureDesc, features, video} ) => {
    
    const [isOpen, setIsOpen] = React.useState(true);
    const openModal = () => {
        setIsOpen(!isOpen);
    }
    
    return (
        <div className='edms-feature-section component-parent' id='Features'>
            <ModalVideo
                channel='youtube'
                isOpen={!isOpen}
                videoId={video}
                onClose={() => setIsOpen(!isOpen)}
            />
            {
                (featureHeading && featureDesc)&& (
                    <div className='heading-container intro-container container '>
                        <h2 className='font-outfit'>{featureHeading}</h2>
                        <p>{featureDesc}</p>
                    </div>
                )        
            }

            <div className='container'>
                <div className='row features-row'>
                {features?.map((feature, index) => {
                   return <FeatureCard key={index} feature={feature} />
                })}
                </div>
            </div>

            <div className='banner-item-content normal-box'>
                <div className='btn-box'>
                    <Link to="/demo" className="default-btn">
                        <i className="flaticon-right"></i>
                        Request a Demo
                    </Link>
                    {video &&
                        <Link
                            to="#play-video"
                            onClick={e => { e.preventDefault(); openModal() }}
                            className="video-btn popup-youtube"
                        >
                            <i className="flaticon-google-play"></i> Watch Video
                        </Link>
                    }
                </div>
            </div>
        </div>
    )
}

export default ProductFeature;